import axiosInstance from "../requestHandler";
import axios from "axios";
import { API_URL } from "../requestHandler";

export const loginRequest = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/v1/login`, data);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getData = async (data) => {
  try {
    
    const response = await axiosInstance.post(`/v1/getData`, data);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getDataFacebook = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/get/facebookCampaign`, data);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
export const getDataPnn = async (data) => {
  try {
    const response = await axiosInstance.post(`${API_URL}/get/PnnCampaign`, data);
    return response;
  } catch (err) {
    console.log(err?.response?.data, "err1");
    return err.response;
  }
};
