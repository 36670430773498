import React from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const GoogleLoginButton = ({setInitLogin}) => {

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        // console.log(tokenResponse.access_token)
        localStorage.setItem("GoogleToken", tokenResponse.access_token)
        setInitLogin(false)
        return tokenResponse.access_token
      } catch (err) {
        console.error(err);

      }
    },
    onError: (error) => console.error('Login Failed:', error),
    flow: 'implicit',
    scope: 'https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/adwords',
  });

  const initiateLogin = () =>{
    console.log("clicked")
    login()
  }

  return (
    <div className="flex h-screen items-center justify-center bg-gray-100">
      <div className="text-center p-6 bg-white shadow-lg rounded-md">
        <h1 className="text-2xl font-bold text-red-600 mb-4">Session Expired</h1>
        <p className="text-gray-700 mb-6">
          Your session has expired. Please log in again to continue.
        </p>
        <button
          onClick={initiateLogin}
          className="px-6 py-2 bg-primary text-white rounded hover:bg-primary-600 transition"
        >
          Login Again
        </button>
      </div>
    </div>
  );
};

export default GoogleLoginButton;