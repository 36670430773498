import { BrowserRouter as Router, Switch, Route, useLocation, useHistory } from "react-router-dom";
import Mainpage from "./components/mainpage";
import Aboutus from "./components/aboutus";
import Contactus from "./components/Contactus";
import TermsCondition from "./components/TermsCondition";
import PrivacyPolicy from "./components/PrivacyPolicy";
import SearchResult from "./components/SearchResult";
import blog from "./components/blog";
import Ads from "./components/Ads";
import Ads1 from "./components/Ads1";
import LoginPage from "./components/Login";
import SiteData from "./components/SiteData";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedRoutes from "./protectedRoutes";
import { useEffect } from "react";
import { redirectUrl } from "./api/Requests/user";
import { API_URL } from "./api/requestHandler";
import { PrivateRoute, LoginRoute } from "./routes/ProtectedRoute";
import AddCampaign from "./components/AddCampaign";
import CampagnTable from "./components/CampaignData";
import CombinedCampaignData from "./components/CombinedCampaignData";
import CampaignDashboard from "./components/CampaignDashboard";
import PnnDashboard from "./components/PnnDashboard";
// import { Notifications } from 'react-push-notification';
// import addNotification from 'react-push-notification';
// import TagManager from 'react-gtm-module'
// const tagManagerArgs = {
//   gtmId: 'G-CPJEFQYWT1'
// }
// TagManager.initialize(tagManagerArgs)
function App() {
  // const [name,setName] = useState("");

  // function warningNotification (){
  //   addNotification({
  //     title: 'Warning',
  //     subtitle: 'Please fill it',
  //     message: 'You have to enter name',
  //     theme: 'red',
  //     closeButton:"X",
  //   })
  // };

  // function successNotification (){
  //   addNotification({
  //     title: 'Success',
  //     subtitle: 'You have successfully submitted',
  //     message: 'Welcome to GeeksforGeeks',
  //     theme: 'light',
  //     closeButton:"X",
  //     backgroundTop:"green",
  //     backgroundBottom:"yellowgreen"
  //   })
  // };

  // function handleSubmit(e){
  //   e.preventDefault();
  //   if(name === "")
  //     warningNotification();
  //   else
  //     successNotification()
  // }

  const URLWatcher = () => {
    const location = useLocation();

    useEffect(() => {
      (async () => {
        const targetUrl = "/search/";

        if (location.pathname.startsWith(targetUrl)) {
          const date = new Date().getTime();
          const r = (Math.random() * 1000).toFixed(3);
          let redirectedUrl;
          const arr = location.pathname.split("/");
          const newArr = [...arr];
          newArr.splice(2, 1, "467");

          if (location?.search) {
            redirectedUrl = `https://search.topicswizard.com${newArr.join("/")}${location.search}&smid=${r + "-" + date}`;
          } else {
            redirectedUrl = `https://search.topicswizard.com${newArr.join("/")}?smid=${r + "-" + date}`;
          }
          await redirectUrl({
            targetUrl: API_URL + location.pathname,
            redirectedUrl,
            smid: r + "-" + date,
            id: arr[2],
          });

          window.location.href = redirectedUrl;
        }
      })();
    }, [location]); // Runs the effect when the location changes

    return null; // No UI needed
  };

  return (
    <>
      <ToastContainer />
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router>
            <URLWatcher />
            <Switch>
              <Route path="/" exact component={Mainpage} />

              <Route exact path="/aboutus" component={Aboutus} />
              <Route exact path="/Contactus" component={Contactus} />
              <Route exact path="/TermsCondition" component={TermsCondition} />
              <Route exact path="/PrivacyPolicy" component={PrivacyPolicy} />
              <Route exact path="/SearchResult" component={SearchResult} />
              <Route exact path="/blog" component={blog} />
              <Route exact path="/ad-test" component={Ads} />
              <Route exact path="/ad1-test" component={Ads1} />
              <PrivateRoute exact path="/campaign-data" component={CampagnTable} />
              <PrivateRoute exact path="/add-campaignID" component={AddCampaign} />
              <PrivateRoute exact path="/campaign-dashboard-facebook" component={CampaignDashboard} />
              <PrivateRoute exact path="/campaign-dashboard-pnn" component={PnnDashboard} />
              <LoginRoute exact path="/login" component={LoginPage} />
              <PrivateRoute exact path="/site-data" component={SiteData} />
              <PrivateRoute exact path="/site-data-combined" component={CombinedCampaignData} />
            </Switch>
          </Router>
        </PersistGate>
      </Provider>
    </>
  );
}

export default App;
