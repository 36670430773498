import DataTable from "react-data-table-component";
import { useEffect, useRef, useState } from "react";
import { getDataPnn } from "../../api/Requests/authentication";
import { useSelector } from "react-redux";
import CustomLoader from "../../common/components/CustomLoader";
// import "./siteData.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getFormattedDate } from "../../utility";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { areDates15DaysApart } from "./utility";
import Header from "../Header";
import TimeClock from "../TimeClock";
import { GoogleOAuthProvider } from "@react-oauth/google";
import GoogleLoginButton from "../../common/GoogleLogin";
import axios from "axios";

const columns = [
    {
        name: "Campaign",
        selector: (row) => row?.campaign?.name || "",
        minWidth: "350px",
        maxWidth: "500px",
        manualColumnResize: true,
        style: { border: "1px solid #ccc" }
    },
    {
        name: "Campaign Id",
        selector: (row) => row?.campaign?.resourceName || "",
        style: { border: "1px solid #ccc" }
    },
    {
        name: "status",
        selector: (row) => row?.campaign?.status || "",
        style: { border: "1px solid #ccc" }
    },
    {
        name: "advertisingChannelType",
        selector: (row) => row?.campaign?.advertisingChannelType || "",
        style: { border: "1px solid #ccc" }
    },
    {
        name: "biddingStrategyType",
        selector: (row) => row?.campaign?.biddingStrategyType || "",
        style: { border: "1px solid #ccc" }
    },
    {
        name: "clicks",
        selector: (row) => Number(row?.metrics?.clicks || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    {
        name: "costMicros",
        selector: (row) => Number(row?.metrics?.costMicros),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    {
        name: "ctr",
        selector: (row) => Number(row?.metrics?.ctr || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    {
        name: "averageCpc",
        selector: (row) => Number(row?.metrics?.averageCpc || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    {
        name: "impressions",
        selector: (row) => Number(row?.metrics?.impressions || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    {
        name: "amountMicros",
        selector: (row) => Number(row?.metrics?.amountMicros || 0),
        sortable: true,
        style: { border: "1px solid #ccc" },
        center: true,
        reorder: true
    },
    // {
    //     name: "Conversion",
    //     selector: (row) => Number(row["0"]?.insights?.data[0]?.conversion) || "NA",
    //     sortable: true,
    //     style: { border: "1px solid #ccc" },
    //     center: true,
    //     reorder: true
    // },
    // {
    //     name: "Budget",
    //     selector: (row) => Number(row.Budget) || "NA",
    //     sortable: true,
    //     style: { border: "1px solid #ccc" },
    //     center: true,
    //     reorder: true
    // },
    // {
    //     name: "Tcpa",
    //     selector: (row) => Number(row.Tcpa) || "NA",
    //     sortable: true,
    //     style: { border: "1px solid #ccc" },
    //     center: true,
    //     reorder: true
    // },
    // {
    //     name: "Ctr",
    //     selector: (row) => Number(row["0"]?.insights?.data[0]?.ctr || 0),
    //     sortable: true,
    //     style: { border: "1px solid #ccc" },
    //     center: true,
    //     reorder: true
    // },

];

const PnnDashboard = () => {
    const [data, setData] = useState({});
    console.log(process.env.client_id);
    const client_id = "528109823797-fg32832fi470hnh9vojl2ra6pfl2sd8q.apps.googleusercontent.com"
    const [initLogin, setInitLogin] = useState(false)
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(500);
    const [isLoading, setIsLoading] = useState(false);
    const [startDate, setStartDate] = useState(new Date());
    const [combinedData, setCombinedData] = useState({
        Profit: 0,
        Earn: 0,
        Spend: 0,
        Budget: 0
    })
    const [endDate, setEndDate] = useState(new Date());
    const [timeZone, setTimeZone] = useState("UTC")
    const [dateRange, setDateRange] = useState(7);

    const handlePerRowsChange = (newPerPage) => {
        setPerPage(newPerPage);
    };

    const handlePageChange = (page) => {
        setPage(page);
    };

    const handleStartDate = (date) => {
        setStartDate(date);
    };

    const getTableData = async (token) => {
        const payload = {
            apiToken: token
        }
        const response = await getDataPnn(payload)
        console.log(response.data)
        setData(response.data)
    }

    const createTotal = (data) => {
        let Budget = 0
        let Spend = 0
        let Earn = 0
        let Profit = 0

        data.map((row) => {
            Budget = Budget + Number(row.Budget || 0)
            Spend = Spend + Number(row["0"]?.insights?.data[0]?.spend || 0)
            Earn = Earn + Number(row.estimated_revenue)
            Profit = Profit + Number(row.estimated_revenue - (row["0"]?.insights?.data[0]?.spend || 0))
        })

        setCombinedData({
            Earn,
            Spend,
            Profit,
            Budget
        })
        console.log({
            Earn,
            Profit,
            Spend,
            Budget
        })
        return
    }
    const formatData = (data) => {
        const uniqueIds = [...new Set(data.map(obj => obj.channelName2))];

        const groupedData = data


        // console.log(groupedData)

        return groupedData

    }

    const verifyGoogleToken = async (token) => {
        try {
            const response = await axios.get(
                `https://www.googleapis.com/oauth2/v3/tokeninfo?access_token=${token}`
            );
            return response.data;
        } catch (error) {
            console.log(error,"err")
            setInitLogin(true)
            // buttonRef.current.click();
            //   console.error('Token verification failed:', error?.response?.data);
            //   throw new Error('Invalid Token');
        }
    };

    const checkTokenAndLogin = async () => {
        const token = localStorage.getItem("GoogleToken")
        if (!token) {
            setInitLogin(true)
        } else {
            const tokenData = await verifyGoogleToken(token)
            if (tokenData?.expires_in > 0) {
                setInitLogin(false)
                await getTableData(token)
            } else {
                setInitLogin(true)
            }
        }
    }

    useEffect(() => {
        if(!initLogin){
            checkTokenAndLogin();
        }
    }, [initLogin]);




    return (

        <div className="page-container" >
            {/* Header */}
            <Header />
            <div className="page-title">
                <h1>Pnn Campaign Data</h1>
                <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", alignContent: "center", gap: 5 }}>
                    <select defaultValue={timeZone} onChange={(e) => setTimeZone(e.target.value)}>
                        <option value="UTC">UTC</option>
                        <option value="Asia/Kolkata">IND</option>
                        <option value="America/Los_Angeles">US</option>
                    </select>
                    <span style={{ fontSize: 15 }}><TimeClock timezone={timeZone} /></span>
                </div>
            </div>
             {initLogin && <GoogleOAuthProvider clientId={client_id}>
                <GoogleLoginButton  setInitLogin={setInitLogin}/>
            </GoogleOAuthProvider>}
            {/* <div className="date-filter-container">
                <div className="date-picker-wrapper">
                    <label htmlFor="start-date">Start Date:</label>
                    <DatePicker
                        id="start-date"
                        selected={startDate}
                        onChange={handleStartDate}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select Start Date"
                        maxDate={new Date()}
                    />
                </div>

                <div className="date-picker-wrapper">
                    <label htmlFor="end-date">End Date:</label>
                    <DatePicker
                        id="end-date"
                        selected={endDate}
                        onChange={(date) => setEndDate(date)}
                        dateFormat="yyyy/MM/dd"
                        placeholderText="Select End Date"
                        maxDate={new Date()}
                    />
                </div>
                <div onClick={() => { getTableData() }} className="date-picker-wrapper " style={{ backgroundColor: "green", color: "white", paddingLeft: 10, paddingRight: 10, cursor: "pointer", justifyContent: "center", alignItems: "center", marginTop: 15, marginBottom: 0, borderRadius: 10 }}>
                    Submit
                </div>
            </div>
            <div className=" w-full" style={{ display: "flex", flexWrap: "wrap", flexDirection: "row", gap: 15, marginBottom: 20, paddingLeft: 20, width: "100%" }}>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Earn</h3>
                    <h4 className=" text-center">{combinedData.Earn}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Spend</h3>
                    <h4 className=" text-center">{combinedData.Spend}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Profit</h3>
                    <h4 className=" text-center">{combinedData.Profit}</h4>
                </div>
                <div className="border border-black py-1.5 px-5 rounded-lg">
                    <h3>Budget</h3>
                    <h4 className=" text-center">{combinedData.Budget}</h4>
                </div>
            </div> */}
            {/* Table Container */}
            <div className="table-site-container">
                <DataTable
                    columns={columns}
                    data={data}
                    showGridlines
                    pagination
                    paginationServer
                    progressPending={isLoading}
                    paginationTotalRows={data?.pagination?.total_rows}
                    paginationPerPage={perPage}
                    paginationRowsPerPageOptions={[500, 550, 600]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    progressComponent={<CustomLoader />}
                    fixedHeader
                    customStyles={{
                        headCells: {
                            style: {
                                border: "1px solid #ccc"
                            },
                        },
                    }}
                    fixedHeaderScrollHeight="calc(100vh - 350px)"
                />
            </div>
        </div>

    );
};

export default PnnDashboard;
